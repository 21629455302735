import * as fromSingleSession from './singleSession/selectors';
import { getElementById } from 'modules/chartingAssets/selectors';

import { isNullOrUndefined } from 'helpers';
import updateArrayItemByIndex from 'helpers/common/array/update-item/by-index';
import collectSubElementData, {
  completeSubElementProps,
  STRATEGIES
} from 'helpers/elements/subElementData/converters/fromSubElements';
import isArrayEmpty from 'helpers/common/array/is-empty';

export const getChartingSessionSystems = (state, chartId) =>
  ((state[chartId]
    && fromSingleSession.getChartingSessionSystems(state[chartId]))
    || []);

export const getChartingSession = (state, chartId) =>
  ((state[chartId]
    && fromSingleSession.getChartingSession(state[chartId]))
    || {});

export const getEnclosedElementsForSystem = (state, chartId, systemId) =>
  ((state[chartId]
    && fromSingleSession.getEnclosedElementsForSystem(state[chartId], systemId))
    || []);

export const isPresentElementInSystem = (state, { chartId, systemId, elementId }) =>
  !isArrayEmpty(
    getEnclosedElementsForSystem(state, chartId, systemId)
      .filter(element => element.id === elementId)
  );

export const getIsPosNegStatus = element =>
  (isNullOrUndefined(element.type)
    ? false
    : element.type);

export const mapElementData = element => ({
  id: element.id,
  isPositive: getIsPosNegStatus(element),
  subElementDataList: element.subElementData,
});

export const collectElementDataForServer = elements =>
  elements.map(mapElementData);

export const updateElementsBySubElement = (state, chartId, systemId, subElement) => {
  const elements = getEnclosedElementsForSystem(state.chartingSessions, chartId, systemId);

  const {
    elementId,
    position,
  } = subElement;

  const indexElementToUpdate = elements.findIndex(iteratedElement => iteratedElement.id === elementId);
  if (indexElementToUpdate === -1) {
    const findElement = getElementById(state.chartingAssets, elementId);
    const subIdsForFindElement = [
      subElement,
    ];
    const newElement = {
      ...findElement,
      subIds: subIdsForFindElement,
      subElementData: collectSubElementData(subIdsForFindElement),
      subElementFavoriteData: collectSubElementData(subIdsForFindElement, completeSubElementProps(STRATEGIES.FAVORITE)),
    };
    return [
      ...elements,
      newElement,
    ];
  }

  const elementByIndex = elements[indexElementToUpdate];
  const {
    subIds: subIdsForElementByIndex,
    subElementData: subElementDataForElementByIndex,
  } = elementByIndex;

  const findSubElementByPosition = subElementDataForElementByIndex.findIndex(iteratedSubElement => iteratedSubElement.position === position);

  if (findSubElementByPosition === -1) {
    const newSubElements = [
      ...subIdsForElementByIndex,
      subElement,
    ];
    const newElement = {
      ...elementByIndex,
      subIds: newSubElements,
      subElementData: collectSubElementData(newSubElements),
      subElementFavoriteData: collectSubElementData(newSubElements, completeSubElementProps(STRATEGIES.FAVORITE)),
    };

    return updateArrayItemByIndex(elements, indexElementToUpdate, newElement);
  }

  const subElementIndex = position - 1;

  const updatedSubIds = updateArrayItemByIndex(subIdsForElementByIndex, subElementIndex, subElement);

  const updatedElement = {
    ...elementByIndex,
    subIds: updatedSubIds,
    subElementData: collectSubElementData(updatedSubIds),
  };

  return updateArrayItemByIndex(elements, indexElementToUpdate, updatedElement);
};

export const getDataForUpdateSystemAfterCreatingSubElement = (state, chartId, systemId, patientId, subElement) => {
  const elementsToSave = updateElementsBySubElement(state, chartId, systemId, subElement);

  return {
    id: chartId,
    systemId,
    patientId,
    elementData: collectElementDataForServer(elementsToSave),
  };
};

const RV_SYSTEMS = [100, 101, 102, 103, 104, 105, 106, 107, 108, 109];

export const getElementIdsByStep = (state, chartId) => {
  return RV_SYSTEMS.reduce((el, systemId) => {
    if (!isArrayEmpty(state?.[chartId]?.[systemId])) {
      return [
        ...el,
        ...state[chartId][systemId].map(element => element.id)
      ];
    }

    return [...el];
  }, []);
};
